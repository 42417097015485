import { useEnv } from '@/common/env'
import { useMessage } from '@/common/message'

// 判断是否是微信浏览器
export function isWeiXin() {
  let ua: string = window.navigator.userAgent.toLowerCase()
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true
  } else {
    return false
  }
}

// 判断是否微信公众号环境
export function isWeChatOfficial() {
  const ua = window.navigator.userAgent.toLowerCase()
  // 检测是否在微信环境中
  return ua.includes('micromessenger') && !ua.includes('miniprogram')
}

/**
 * 判断是否运行在微信小程序环境
 * @returns
 */
export function isWeChatMiniProgram() {
  return typeof wx !== 'undefined' && typeof wx.getSystemInfoSync === 'function'
}

export function scrollLoadMore() {
  // 监听页面滚动
  document.addEventListener('scroll', () => {
    // 距离顶部或底部的阈值
    const threshold = 20
    // 距顶部
    let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
    // 可视区高度
    let clientHeight = document.documentElement.clientHeight || document.body.clientHeight
    // 滚动条总高度
    let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
    console.log(scrollTop + clientHeight + threshold >= scrollHeight)
    // 滚动触底
    if (scrollTop + clientHeight + threshold >= scrollHeight) {
      console.log('滚动触底')
    }
  })
}

export function copyTestReportUrl(page: string) {
  wx.setClipboardData({
    data: `${useEnv.baseUrl}${page}`,
    success() {
      useMessage.info('已复制到剪贴板，请在浏览器打开下载')
    },
    fail() {
      useMessage.error('复制链接失败')
    }
  })
}

export function copyDownloadUrl(url: string) {
  wx.setClipboardData({
    data: url,
    success() {
      useMessage.info('已复制到剪贴板，请在浏览器打开下载')
    },
    fail() {
      useMessage.error('复制链接失败')
    }
  })
}